import React, { useState, useEffect } from "react";

import AuthService from "../../services/auth.service";
//import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';

import { Form, Button, Alert, InputGroup, FloatingLabel } from "react-bootstrap"
import { Formik } from "formik";
import * as Yup from "yup";

import styles from './Auth.module.scss';

const Register = (props) => {
  const { t } = useTranslation();

  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const [showPass, setShowPass] = useState(false);

  const handleSubmit = async (data, form) => {

    setMessage("");
    setLoading(true);

    AuthService.register(data.firstName, data.lastName, data.email, data.password, data.countryId)
      .then(() => setRedirect(true),
        (error) => {

          if (error.response.data && error.response.data.errorCode === 101)
            form.setErrors(error.response.data.errors);
          else {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            setMessage(resMessage);

            console.log(error.response);
          }

          //console.log("Processing validation")
          //if (error.response.data.errorCode == 101)
          //  form.setErrors(error.response.data.errors);

          setLoading(false);
        }
      )
      .catch(err => {
        //  console.log("caught");
        //console.log(err);
      });
    
  };

  useEffect(() => {

  }, []);

  const showPassClickHandler = (e) => {
    setShowPass(!showPass);
  };

  return (
    <>
      {redirect && (
        <Alert key="success" variant="success">
          <Trans i18nKey="register.success">
            Registered successfully. Please login{" "}<Alert.Link href="/login">here</Alert.Link>.
          </Trans>
        </Alert>
      )}

      {!redirect && (
        <>
          <h1 className={`mb-3 text-center ${styles.header}`}>{t('register.header')}</h1>
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              password: "",
              countryId: "1"
            }}
            validationSchema={Yup.object({
              firstName: Yup.string()
                .required(t("validation.required")),
              lastName: Yup.string()
                .required(t("validation.required")),
              email: Yup.string()
                .email(t("validation.invalidEmail"))
                .required(t("validation.required")),
              password: Yup.string()
                .required(t("validation.required")),
            })}
            onSubmit={handleSubmit}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>

                <Form.Group className={`form-floating ${styles.inputWrapper}`}>
                  <Form.Control
                    type="text"
                    name="firstName"
                    placeholder="John"
                    value={values.firstName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    isInvalid={touched.firstName && !!errors.firstName}
                    className={styles.input}
                  />
                  <Form.Label>{t('register.yourFirstName')}</Form.Label>
                  <Form.Control.Feedback type="invalid" className="mb-4">{errors.firstName}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className={`form-floating ${styles.inputWrapper}`}>
                  <Form.Control
                    type="text"
                    name="lastName"
                    placeholder="John"
                    value={values.lastName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    isInvalid={touched.lastName && !!errors.lastName}
                    className={styles.input}
                  />
                  <Form.Label>{t('register.yourLastName')}</Form.Label>
                  <Form.Control.Feedback type="invalid" className="mb-4">{errors.lastName}</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className={`form-floating ${styles.inputWrapper}`}>
                  <Form.Control
                    type="text"
                    name="email"
                    placeholder="name@example.com"
                    value={values.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    isInvalid={touched.email && !!errors.email}
                    className={styles.input}
                  />
                  <Form.Label>{t('register.yourEmail')}</Form.Label>
                  <Form.Control.Feedback type="invalid" className="mb-4">{errors.email}</Form.Control.Feedback>
                </Form.Group>

                <InputGroup className="mb-3" >
                  <FloatingLabel label={t('auth.password')}>
                    <Form.Control
                      type={showPass ? "text" : "password"}
                      name="password"
                      placeholder="Password"
                      value={values.password}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      isInvalid={touched.password && !!errors.password}
                      className={styles.input}
                    />
                    {/*<Form.Control.Feedback type="invalid" className="mb-4">{errors.password}</Form.Control.Feedback>*/}
                  </FloatingLabel>                  
                  <InputGroup.Text id="basic-addon1" onClick={showPassClickHandler} className={styles.showPassIcon}>
                    <i className={showPass ? 'fas fa-eye-slash' : 'fas fa-eye'}></i>
                  </InputGroup.Text>                  
                </InputGroup>
                {errors.password &&
                  <Alert key="danger" variant="danger" className="mt-3">
                    {errors.password}
                  </Alert>
                }                

                <Form.Group className={`form-floating ${styles.inputWrapper}`}>
                  <Form.Select                    
                    name="countryId"                    
                    value={values.countryId}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    isInvalid={touched.countryId && !!errors.countryId}
                    className={styles.input}
                  >                    
                    <option value="1">{t('countries.notSelected')}</option>
                    <option value="2">{t('countries.belarus')}</option>                    
                  </Form.Select>

                  <Form.Label>{t('register.yourCountry')}</Form.Label>
                  <Form.Control.Feedback type="invalid" className="mb-4">{errors.countryId}</Form.Control.Feedback>
                  <Form.Text id="passwordHelpBlock" muted>
                    {t('countries.disclaimer')}
                  </Form.Text>
                </Form.Group>
                

                <Button className={`w-100 btn-lg ${styles.submitButton}`} type="submit" disabled={loading}>
                  {loading && (
                    <span className="spinner-border spinner-border-sm"></span>
                  )}
                  <span> {t('register.submitButton')}</span>
                </Button>
                {message && (
                  <Alert key="danger" variant="danger" className="mt-3">
                    {message}
                  </Alert>
                )}
              </Form>
            )}
          </Formik>
          <p className="mt-3">
            {t("register.alreadyRegistered")}<br />
            <a href="/login">{t("register.signInHere")}</a>
          </p>
        </>
      )}
    </>
  );
}

export { Register };