import styles from './HeaderTable.module.scss';
import Item from '../Item';
import ItemNumberTable from '../Items/ItemNumberTable'
export default function HeaderTable({ items }) {
  return (
    <div className={styles.headerTable}>
      {items.map((item, index) => (
        <div key={index} className={styles.headerItem}>
          {item.answerType === 20 && <ItemNumberTable item={item} />}
          {item.answerType !== 20 && <Item item={item} />}
        </div>
      ))}
    </div>
  );
}
