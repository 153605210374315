import React, { useState, useEffect, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Button, Modal, Alert, Col, Row, Stack } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import api from "../../../services/api";
import Loading from '../Loading'
import { dateFormatter } from '../../../utils/dateFormatter'

import Highcharts from 'highcharts/highstock'

import styles from './UserEcg12.module.scss';
import * as CommonIcons from '../../CommonIcons';

import { OneIntervalChart } from './OneIntervalChart';

export default function UserDevices({ apiEcg12BaseUrl }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    const loadRecords = () => {
      api
        .get(apiEcg12BaseUrl)
        .then((response) => {          
          setData(response.data);

          setLoading(false);
        });
    };

    loadRecords();
    
  }, [apiEcg12BaseUrl]);


  if (loading)
    return <Loading />;

  return (
    <>
    <div className={styles.ecg12Table}>
        {data.items.map(c => {
          return (
            <div key={c.uid} className={`${styles.oneChart}`}>              
              <OneIntervalChart
                displayName={c.displayName}
                data={c.data}
                chartName={c.chartName}
                markers={c.markers}
                containerClassName={styles.oneChartContainer}
                recordDate={c.recordDate}
              />
            </div>
        )
      }) }
    </div>
    </>
  );
}
