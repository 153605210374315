import styles from './ItemNumberTable.module.scss';
import { dateOnlyFormatter } from '../../../utils/dateFormatter'

export default function ItemNumberTable({ item }) {

  return (
    <>
      <div className={styles.itemContainer}>
        {item.displayName && item.displayName !== '' &&
          <div className={styles.header}>
            <b>{item.displayName}</b>
          </div>}

        <div className={styles.textContainer}>          
          <div className={styles.text}>{item.textValue}</div>
        </div>        
      </div>
    </>
  );
}

