import styles from './ItemImage.module.scss';
import { dateOnlyFormatter } from '../../../utils/dateFormatter'

export default function ItemImage({ item }) {

  return (
    <>
      <div className={styles.itemContainer}>
        {item.displayName && item.displayName !== '' &&
          <div className={styles.header}>
            <b>{item.displayName}</b>
          </div>}

        <div className={styles.imageContainer}>
          <img src={item.image.url} alt={item.image.alt} className={styles.image} />
          {/*<div className={styles.image}>{item.image.alt}</div>*/}
        </div>
        {((item.image.text && item.image.text !== "") || item.image.recordDate) && 
          <div className={styles.bottomText}>
            {item.image.text && item.image.text !== "" ? item.image.text : dateOnlyFormatter(item.image.recordDate)}
          </div>
        }
      </div>
    </>
  );
}

