import React, { useState  } from 'react';
import api from "../../services/api";
import { Row, Col, Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import ModalMessage from '../Shared/ModalMessage'


function RequestConsultationConfirmModal({ recordUid, requestSentCallback }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [modalQuestionShow, setModalQuestionShow] = useState(false);

  const [modalConfirmShow, setModalConfirmShow] = useState(false);

  const onConfirmClick = () => {
    setLoading(true);
    api.post("/consultation/user/create", { recordUid: recordUid }
    ).then(response => {
      setLoading(false);
      setModalConfirmShow(true);
      if (requestSentCallback) requestSentCallback();
    },
      (error) => {
        setLoading(false);
      });

  };

  const onHideQuestion = () => {
    setModalQuestionShow(false);
  };

  return (
    <>
      <Row className="mt-3 mb-5">
        <Col>
          <Button variant="primary" onClick={() => setModalQuestionShow(true)}>{t('recordView.requestConsultationButton')}</Button>
        </Col>
      </Row>

      
      {modalQuestionShow && modalConfirmShow &&
        <ModalMessage
          show={modalConfirmShow}
          onHide={() => { setModalConfirmShow(false); onHideQuestion(); }}
          header={t('consultation.createRequest.success.header')}
          body={t('consultation.createRequest.success.text')}
          button={t('consultation.createRequest.success.button')}
        />
      }

      {modalQuestionShow && !modalConfirmShow &&
        <Modal
          show={modalQuestionShow}
          onHide={onHideQuestion}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {t('consultation.createRequest.header')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              {t('consultation.createRequest.body')}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={onConfirmClick}>
              {loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              {t('consultation.createRequest.okButton')}</Button>
            <Button variant="warning" onClick={onHideQuestion}>{t('consultation.createRequest.cancelButton')}</Button>
          </Modal.Footer>
        </Modal>
      }
      
    </>
  );
}

export default RequestConsultationConfirmModal;